import { stateFactory } from "./state";
import {
    PaymentMethodsActionType,
    type PaymentMethodsRepositoryState,
    type PaymentMethodRepositoryAction
} from "./types";

export function reducer(state: PaymentMethodsRepositoryState, action: PaymentMethodRepositoryAction): PaymentMethodsRepositoryState {
    switch (action.type) {
        case PaymentMethodsActionType.FetchPaymentMethods: {
            return {
                ...state,
                ...action.payload
            };
        }

        case PaymentMethodsActionType.Reset: {
            return stateFactory();
        }

        default:
            return state;
    }
}

export default reducer;
