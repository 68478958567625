import { memo } from "react";
import { compareIds } from "util/support";
import type { PaymentMethod as PaymentMethodType } from "features/paymentMethods/types";
import { useAccordion } from "ui/atoms/Accordion";
import { Collection } from "ui/molecules/Resource";
import ResourceLayout from "ui/layouts/Resource";
import { useBootUnsavedChanges } from "ui/widgets/UnsavedChanges";
// import AddNewMethod from "./AddNewMethod";
import PaymentMethodProvider from "./PaymentMethodsProvider";
import usePaymentMethods from "./usePaymentMethods";
import PaymentMethod from "./PaymentMethod";

const PaymentMethods = () => {
    const {paymentMethods, isPaymentMethodsLoading }= usePaymentMethods();
    const { expanded, handleChange } = useAccordion();

    useBootUnsavedChanges({
        cancelActionContentSlot: 'Stay on page',
        content: {
            dialogContent: [
                `The method hasn't been saved. Do you want to discard your action(s)?`
            ],
            actionContent: 'Discard'
        }
    });

    return (
        <PaymentMethodProvider>
            <ResourceLayout
                breadcrumbsProps={{
                    heading: 'Payment Method Setup',
                    links: [{ name: '' }],
                    // Enable once implemented
                    // action: (
                    //     <AddNewMethod />
                    // )
                }}
            >
                <Collection
                    isLoading={isPaymentMethodsLoading}
                    collection={paymentMethods}
                    renderResource={(paymentMethod: PaymentMethodType) => (
                        <PaymentMethod
                            key={paymentMethod.coreId}
                            expanded={compareIds(String(expanded), paymentMethod.coreId)}
                            onChange={handleChange(paymentMethod.coreId)}
                            paymentMethod={paymentMethod}
                        />
                    )}
                />
            </ResourceLayout>
        </PaymentMethodProvider>
    );
};

export default memo(PaymentMethods);
