import { useFormableTab } from "ui/widgets/StatefulTabs";
import { PaymentMethodDetailTab, type PaymentMethodProviderProps } from "./types";
import usePaymentMethodBasicInfo from "./usePaymentMethodBasicInfo";
import usePaymentMethodSpecInParams from "./usePaymentMethodSpecInParams";

export default function usePaymentMethod({ paymentMethod, ...accordionProps }: PaymentMethodProviderProps) {
    const state = {
        [PaymentMethodDetailTab.BasicInfo]: usePaymentMethodBasicInfo(paymentMethod),
        [PaymentMethodDetailTab.SpecificInputParameters]: usePaymentMethodSpecInParams(paymentMethod)
    };

    const formableTab = useFormableTab<PaymentMethodDetailTab>({
        state,
        entries: [
            ['1', PaymentMethodDetailTab.BasicInfo],
            ['2', PaymentMethodDetailTab.SpecificInputParameters]
        ]
    });

    const getPaymentMethod = () => paymentMethod;

    const getAccordionProps = () => accordionProps;

    const selectBasicInfo = () =>
        state[PaymentMethodDetailTab.BasicInfo];

    const selectSpecificInputParameters = () =>
        state[PaymentMethodDetailTab.SpecificInputParameters];

    const hasActiveChanges = () => {
        const tabs = [
            selectBasicInfo,
            selectSpecificInputParameters
        ];

        if (tabs.every(tab => tab().isValid)) {
            return tabs.some(tab => tab().hasChanges());
        }

        return false;
    };

    const getChangedValues = (): Partial<PaymentMethodProviderProps['paymentMethod']> => ({
        ...selectBasicInfo()
            .getChangedValues(),
        ...selectSpecificInputParameters()
            .getChangedValues(),
        coreId: paymentMethod.coreId
    });

    return {
        ...formableTab,
        hasActiveChanges,
        getPaymentMethod,
        getAccordionProps,
        selectBasicInfo,
        getChangedValues,
        selectSpecificInputParameters
    };
};
