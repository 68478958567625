import { DomainRepository, type Dispatcher } from "hooks/useDomainState";
import {
    PaymentMethodsActionType,
    type PaymentMethodsRepository,
    type PaymentMethodsRepositoryState
} from "./types";

export class Repository extends DomainRepository<
    PaymentMethodsRepositoryState,
    PaymentMethodsActionType
> implements PaymentMethodsRepository {
    public constructor(
        public readonly state: PaymentMethodsRepositoryState,
        public readonly dispatch: Dispatcher<PaymentMethodsActionType>,
    ) {
        super(state, dispatch);
    }

    public get isLoading() {
        return false;
    }

    public get isEmpty() {
        return false;
    }

    public get isError() {
        return false;
    }

    public async fetchPaymentMethods() {
        if (this.shouldSendRequest(this.state.apiState)) {
            return;
        }

        this.dispatch({
            type: PaymentMethodsActionType.FetchPaymentMethods,
            payload: {}
        });
    }

    public reset() {
        this.dispatch({
            type: PaymentMethodsActionType.Reset,
            payload: undefined
        });
    }
}

export default Repository;
