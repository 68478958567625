import { memo, type PropsWithChildren } from "react";
import { WebRoutes } from "consts/enpoints/web";
import useAuth from "features/auth/useAuth";
import Navigate from "./Navigate";

const Protected = ({ children }: PropsWithChildren) => {
    const {
        isAuthenticated,
        isPending,
    } = useAuth();

    if (isPending()) {
        return null;
    }

    if (!isAuthenticated()) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return (
            <Navigate
                reload
                replace
                to={WebRoutes.Login}
            />
        );
    }

    return (
        <>
            {children}
        </>
    );
};

export default memo(Protected);
