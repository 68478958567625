import { KYCServiceType } from "consts/kyc";
import type { ID } from "types";
import { route } from "util/support";

export const enum ApiRouteTypes {
  GetTransactionFilters = 'GetTransactionFilters',
  GetBankSdk = 'GetBankSdk',
  GetToken = 'GetToken',
  GetTransactionById = 'GetTransactionById',
  GetTransactionHistory = 'GetTransactionHistory',
  GetSendExport = 'GetSendExport',
  GetClearExport = 'GetClearExport',
  GetDownloadCSV = 'GetDownloadCSV',
  GetTransactionOrigins = 'GetTransactionOrigins',
  // New API
  // General
  GetConstants = 'GetConstants',
  GetCountries = 'GetCountries',
  GetUsersPreferences = 'GetUsersPreferences',
  PutUsersPreferences = 'PutUsersPreferences',
  // Files
  GetFile = 'GetFile',
  PostFile = 'PostFile',
  DeleteFile = 'DeleteFile',
  // Authentication
  PostLogin = 'PostLogin',
  PostRefreshToken = 'PostRefreshToken',
  PostLogout = 'PostLogout',
  // Transactions
  GetTransactions = 'GetTransactions',
  GetEsTransactions = 'GetEsTransactions',
  PingTransactions = 'PingTransactions',
  // Notifications
  GetInNotifications = 'GetInNotifications',
  GetOutNotifications = 'GetOutNotifications',
  // Notiication channels
  GetNotificationChannels = 'GetNotificationChannels',
  // Merchants
  GetMerchants = 'GetMerchants',
  GetMerchantUsers = 'GetMerchantUsers',
  GetMerchantByIdUsers = 'GetMerchantByIdUsers',
  PostMerchant = 'PostMerchant',
  PutMerchant = 'PutMerchant',
  GetMerchantGroups = 'GetMerchantGroups',
  PostMerchantsGroupsPivot = 'PostMerchantsGroupsPivot',
  GetMerchantSettings = 'GetMerchantSettings',
  PutMerchantSettings = 'PutMerchantSettings',
  GetMerchantKYCSettings = 'GetMerchantKYCSettings',
  PutMerchantKYCSettings = 'PutMerchantKYCSettings',
  GetPayoutAccounts = 'GetPayoutAccounts',
  PutPayoutAccounts = 'PutPayoutAccounts',
  DeletePayoutAccounts = 'DeletePayoutAccounts',
  GetPayoutCryptoAccounts = 'GetPayoutCryptoAccounts',
  PutPayoutCryptoAccounts = 'PutPayoutCryptoAccounts',
  DeletePayoutCryptoAccounts = 'DeletePayoutCryptoAccounts',
  GetMerchantDomains = 'GetMerchantDomains',
  DeleteMerchantDomains = 'DeleteMerchantDomains',
  PostMerchantDomains = 'PostMerchantDomains',
  GetMerchantBusinesses = 'GetMerchantBusinesses',
  DeleteMerchantBusinesses = 'DeleteMerchantBusinesses',
  PostMerchantBusinesses = 'PostMerchantBusinesses',
  // Ledgers
  GetLedgers = 'GetLedgers',
  PostLedgers = 'PostLedgers',
  // Merchant pivots
  GetMerchantsNotificationChannelsPivot = 'GetMerchantsNotificationChannelsPivot',
  PutMerchantsNotificationChannelsPivot = 'PutMerchantsNotificationChannelsPivot',
  // Consumers
  GetConsumers = 'GetConsumers',
  PostConsumer = 'PostConsumer',
  GetConsumersBlacklist = 'GetConsumersBlacklist',
  PostConsumersBlacklist = 'PostConsumersBlacklist',
  DeleteConsumersBlacklist = 'DeleteConsumersBlacklist',
  GetConsumerByEmailOrId = 'GetConsumerByEmailOrId',
  PutConsumer = 'PutConsumer',
  GetConsumerEmails = 'GetConsumerEmails',
  PutConsumerEmails = 'PutConsumerEmails',
  GetConsumersKYCSettings = 'GetConsumersKYCSettings',
  PostConsumersKYCSettings = 'PostConsumersKYCSettings',
  GetConsumersManualBulkKYCsImportHistoryPivot = 'GetConsumersManualBulkKYCsImportHistoryPivot',
  // Payment methods
  GetPaymentMethods = 'GetPaymentMethods',
  PostPaymentMethods = 'PostPaymentMethods',
  // Setups
  GetApiDomains = 'GetApiDomains',
  GetSetupsKYCSwitch = 'GetSetupsKYCSwitch',
  PutSetupsKYCSwitch = 'PutSetupsKYCSwitch',
  GetTicketingSystemSwitch = 'GetTicketingSystemMainSwitch',
  PutTicketingSystemSwitch = 'PutTicketingSystemMainSwitch',
  GetTicketingSystemZendeskSwitch = 'GetTicketingSystemZendeskTicketingSwitch',
  PutTicketingSystemZendeskSwitch = 'PutTicketingSystemZendeskTicketingSwitch',
  // Users
  GetUsers = 'GetUsers',
  PostUser = 'PostUser',
  GetRoles = 'GetRoles',
  DeleteUser = 'DeleteUser',
  // User pivots
  GetUsersMerchants = 'GetUsersMerchants',
  PostUserMerchant = 'PostUserMerchant',
  DeleteUserMerchant = 'DeleteUserMerchant',
  GetUsersMors = 'GetUsersMors',
  PostUserMor = 'PostUserMor',
  DeleteUserMor = 'DeleteUserMor',
  // Merchant Payouts
  GetEsMerchantPayoutFiles = 'GetEsMerchantPayoutFiles',
  GetMerchantPayoutSummaryFiles = 'GetMerchantPayoutSummaryFiles',
  GetMerchantPayoutFiles = 'GetMerchantPayoutFiles',
  GetMerchantPayoutFilesById = 'GetMerchantPayoutFilesById',
  GetMerchantPayoutCalculations = 'GetMerchantPayoutCalculations',
  GetMerchantPayoutFileTaxCalculations = 'GetMerchantPayoutFileTaxCalculations',
  PostMerchantPayoutFileTaxCalculations = 'PostMerchantPayoutFileTaxCalculations',
  DeleteMerchantPayoutFileTaxCalculations = 'DeleteMerchantPayoutFileTaxCalculations',
  GetTransactionsMerchantPayoutIncrementsPivot = 'GetTransactionsMerchantPayoutIncrementsPivot',
  GetMerchantPayoutIncrementTaxes = 'GetMerchantPayoutIncrementTaxes',
  PostMerchantPayoutIncrementTaxes = 'PostMerchantPayoutIncrementTaxes',
  PutMerchantPayoutIncrementTaxes = 'PutMerchantPayoutIncrementTaxes',
  DeleteMerchantPayoutIncrementTaxes = "DeleteMerchantPayoutIncrementTaxes",
  GetMerchantPayoutIncrements = 'GetMerchantPayoutIncrements',
  PutMerchantPayoutIncrements = 'PutMerchantPayoutIncrements',
  PostMerchantPayoutIncrementTaxesBulk = 'PostMerchantPayoutIncrementTaxesBulk',
  GetMerchantPayoutIncrementExchangeRates = 'GetMerchantPayoutIncrementExchangeRates',
  PostMerchantPayoutIncrementExchangeRates = 'PostMerchantPayoutIncrementExchangeRates',
  DeleteMerchantPayoutIncrementExchangeRates = 'DeleteMerchantPayoutIncrementExchangeRates',
  PostMerchantPayoutIncrementExchangeRatesBulk = 'PostMerchantPayoutIncrementExchangeRatesBulk',
  PostMerchantPayoutFiles = 'PostMerchantPayoutFiles',
  PutMerchantPayoutFiles = 'PutMerchantPayoutFiles',
  PreviewMerchantPayoutFilesSettlementXLS = 'PreviewMerchantPayoutFilesSettlementXLS',
  GetMerchantPayoutFilesTransactionsCSV = 'GetMerchantPayoutFilesTransactionsCSV',
  GetMerchantPayoutSummaryFilesTransactionsCSV = 'GetMerchantPayoutSummaryFilesTransactionsCSV',
  GetMerchantPayoutFilesStillPendingSettlementCSV = 'GetMerchantPayoutFilesStillPendingSettlementCSV',
  GenerateMerchantPayoutFilesSettlements = 'GenerateMerchantPayoutFilesSettlements',
  DeleteMerchantPayoutFiles = "DeleteMerchantPayoutFiles",
  PaidMerchantPayoutFiles = "PaidMerchantPayoutFiles",
  PostMerchantPayoutSummaryFiles = 'PostMerchantPayoutSummaryFiles',
  DeleteMerchantPayoutSummaryFiles = 'DeleteMerchantPayoutSummaryFiles',
  GetMerchantsMethodsPivot = 'GetMerchantsMethodsPivot',
  PostMerchantsMethodsPivot = 'PostMerchantsMethodsPivot',
  PutMerchantsMethodsPivot = 'PutMerchantsMethodsPivot',
  GetMerchantsMethodsPivotHistory = 'GetMerchantsMethodsPivotHistory',
  // KYCs
  GetKycs = 'GetKycs',
  PostKycs = 'PostKycs',
  DeleteKYCs = 'DeleteKYCs',
  PostManualBulkKYCsImportHistory = 'PostManualBulkKYCsImportHistory',
  // Manual Bulk KYCs Import History
  GetManualBulkKYCsImportHistory = 'GetManualBulkKYCsImportHistory',
  // KYC Settings
  GetKYCSettings = 'GetKYCSettings',
  PostKYCSettings = 'PostKYCSettings',
  GetBitsoBanks = 'GetBitsoBanks'
};

export const ApiRoutes = {
  [ApiRouteTypes.GetTransactionFilters]: '/getTransactionFilters',
  [ApiRouteTypes.GetBankSdk]: (queryString: string) => `/getBankSdk${queryString}`,
  [ApiRouteTypes.GetToken]: '/getToken',
  [ApiRouteTypes.GetTransactionById]: (transactionId: ID) => `/getTransaction/${transactionId}`,
  [ApiRouteTypes.GetSendExport]: (queryString: string) => `/sendExport${queryString}`,
  [ApiRouteTypes.GetClearExport]: '/clearExport',
  [ApiRouteTypes.GetDownloadCSV]: '/downloadCsv',
  [ApiRouteTypes.GetTransactionOrigins]: '/transaction-origins',
  [ApiRouteTypes.GetConsumers]: '/consumers',
  [ApiRouteTypes.PostConsumer]: '/consumers',
  [ApiRouteTypes.GetConsumersBlacklist]: '/consumers-blacklist',
  [ApiRouteTypes.PostConsumersBlacklist]: '/consumers-blacklist',
  [ApiRouteTypes.DeleteConsumersBlacklist]: (coreIdOrEmail: ID) =>
    route(['consumers-blacklist', coreIdOrEmail]),
  [ApiRouteTypes.GetConsumerEmails]: route(['consumer-emails']),
  [ApiRouteTypes.PutConsumerEmails]: (coreId: ID) =>
    route(['consumer-emails', coreId]),
  [ApiRouteTypes.GetConsumerByEmailOrId]: (emailOrId: string) =>
    route(['consumers', emailOrId]),
  [ApiRouteTypes.PostFile]: '/files',
  [ApiRouteTypes.GetFile]: (fileKey: string) =>
    route(['/files', fileKey]),
  [ApiRouteTypes.DeleteFile]: (fileKey: string) =>
    route(['/files', fileKey]),
  [ApiRouteTypes.PutConsumer]: (coreIdOrEmail: ID) =>
    route(['consumers', coreIdOrEmail]),
  [ApiRouteTypes.GetPayoutAccounts]: '/payout-accounts',
  [ApiRouteTypes.PutPayoutAccounts]: (coreId?: ID) =>
    route(['payout-accounts', coreId]),
  [ApiRouteTypes.DeletePayoutAccounts]: (coreId: ID) =>
    route(['payout-accounts', coreId]),
  [ApiRouteTypes.GetPayoutCryptoAccounts]: '/payout-crypto-accounts',
  [ApiRouteTypes.PutPayoutCryptoAccounts]: (coreId?: ID) =>
    route(['payout-crypto-accounts', coreId]),
  [ApiRouteTypes.DeletePayoutCryptoAccounts]: (coreId: ID) =>
    route(['payout-crypto-accounts', coreId]),
  // [ApiRouteTypes.PostSaveUser]: '/saveUser',
  // [ApiRouteTypes.PostUpdateUser]: (userId: ID) => `/updateUser/${userId}`,
  // New API
  // [ApiRouteTypes.GetUser]: (userId: ID) => `/users/${userId}`,
  [ApiRouteTypes.PostLogin]: '/login',
  [ApiRouteTypes.PostRefreshToken]: '/refresh',
  [ApiRouteTypes.PostLogout]: '/logout',
  [ApiRouteTypes.GetConstants]: '/constants',
  [ApiRouteTypes.PingTransactions]: (coreId?: string) =>
    route([`/ping-transactions`, coreId]),
  [ApiRouteTypes.GetTransactions]: (coreId?: string) =>
    route([`/transactions`, coreId]),
  [ApiRouteTypes.GetEsTransactions]: '/es-transactions',
  [ApiRouteTypes.GetTransactionHistory]: (
    transactionId: string,
    transactionsGuid: string
  ) => `/transactions-history?transactionId=${transactionId}&transactionGuid=${transactionsGuid}`,
  [ApiRouteTypes.GetMerchants]: '/merchants',
  [ApiRouteTypes.GetMerchantUsers]: '/users',
  [ApiRouteTypes.GetMerchantByIdUsers]: route(['users']),
  [ApiRouteTypes.GetMerchantKYCSettings]: '/merchant-kyc-settings',
  [ApiRouteTypes.GetLedgers]: route(['ledgers']),
  [ApiRouteTypes.PostLedgers]: (coreId?: ID) => route(['ledgers', coreId]),
  [ApiRouteTypes.PutMerchantKYCSettings]: (
    merchantId: ID,
    kycTypeId: KYCServiceType
  ) => route(['merchant-kyc-settings', [merchantId, kycTypeId].join('_')]),
  [ApiRouteTypes.GetMerchantSettings]: '/merchant-settings',
  [ApiRouteTypes.PutMerchantSettings]: (coreId?: ID) =>
    route(['merchant-settings', coreId]),
  [ApiRouteTypes.GetMerchantsNotificationChannelsPivot]: '/merchants-notification-channels-pivot',
  [ApiRouteTypes.PutMerchantsNotificationChannelsPivot]: (
    merchantId: ID,
    notificationChannelId: ID
  ) =>
    route(['merchants-notification-channels-pivot', [merchantId, notificationChannelId].join('_')]),
  [ApiRouteTypes.GetConsumersKYCSettings]: '/consumer-kyc-settings',
  [ApiRouteTypes.PostConsumersKYCSettings]: '/consumer-kyc-settings',
  [ApiRouteTypes.GetKYCSettings]: '/kyc-settings',
  [ApiRouteTypes.PostKYCSettings]: (merchantId?: ID) => route(['kyc-settings', merchantId]),
  [ApiRouteTypes.PostMerchant]: (merchantId?: ID) => route(['/merchants', merchantId]),
  [ApiRouteTypes.PutMerchant]: (coreId: ID) => route(['merchants', coreId]),
  [ApiRouteTypes.GetMerchantGroups]: '/merchant-groups',
  [ApiRouteTypes.PostMerchantsGroupsPivot]: '/merchants-groups-pivot',
  [ApiRouteTypes.GetPaymentMethods]: '/payment-methods',
  [ApiRouteTypes.PostPaymentMethods]: (coreId?: ID) => route(['/payment-methods', coreId]),
  [ApiRouteTypes.GetApiDomains]: '/setups/MORS',
  [ApiRouteTypes.GetInNotifications]: '/notifications-in-history',
  [ApiRouteTypes.GetOutNotifications]: '/notifications-out-history',
  [ApiRouteTypes.GetNotificationChannels]: '/notification-channels',
  [ApiRouteTypes.GetCountries]: '/countries',
  [ApiRouteTypes.GetUsers]: (userId?: ID) => route(['/users', userId]),
  [ApiRouteTypes.DeleteUser]: (coreId?: ID) =>
  route(['/users', coreId]),
  [ApiRouteTypes.PostUser]: (userId?: ID) => route(['/users', userId]),
  [ApiRouteTypes.GetRoles]: '/roles',
  [ApiRouteTypes.GetUsersMerchants]: (userId?: ID) =>
    route(['/users-merchants-pivot', `?userId[in][]=${userId}`], ''),
  [ApiRouteTypes.GetUsersMors]: (userId?: ID) =>
    route(['/users-mors-pivot', `?userId[in][]=${userId}`], ''),
  [ApiRouteTypes.PostUserMerchant]: '/users-merchants-pivot',
  [ApiRouteTypes.DeleteUserMerchant]: (merchantId: ID, userId: ID) =>
    route(['/users-merchants-pivot', `${merchantId}_${userId}`]),
  [ApiRouteTypes.PostUserMor]: '/users-mors-pivot',
  [ApiRouteTypes.DeleteUserMor]: (MOR: string, userId: ID) =>
    route(['/users-mors-pivot', `${MOR}_${userId}`]),
  // Merchant Payouts
  [ApiRouteTypes.GetMerchantPayoutSummaryFiles]: '/merchant-payout-summary-files',
  [ApiRouteTypes.PostMerchantPayoutSummaryFiles]: "/merchant-payout-summary-files",
  [ApiRouteTypes.GetMerchantPayoutFiles]: '/merchant-payout-files',
  [ApiRouteTypes.GetEsMerchantPayoutFiles]: '/es-merchant-payout-files',
  [ApiRouteTypes.GetMerchantPayoutFilesById]: (coreId: ID) =>
    route(['/merchant-payout-files', coreId]),
  [ApiRouteTypes.PostMerchantPayoutFiles]: "/merchant-payout-files",
  [ApiRouteTypes.PutMerchantPayoutFiles]: (coreId: ID) =>
    route(['merchant-payout-files', coreId]),
  [ApiRouteTypes.GetMerchantPayoutCalculations]: '/merchant-payout-calculations',
  [ApiRouteTypes.GetMerchantPayoutFileTaxCalculations]: '/merchant-payout-file-tax-calculations',
  [ApiRouteTypes.PostMerchantPayoutFileTaxCalculations]: (coreId?: ID) =>
    route(['/merchant-payout-file-tax-calculations', coreId]),
  [ApiRouteTypes.DeleteMerchantPayoutFileTaxCalculations]: (coreId: ID) =>
    route(['/merchant-payout-file-tax-calculations', coreId]),
  [ApiRouteTypes.GetTransactionsMerchantPayoutIncrementsPivot]: '/transactions-merchant-payout-increments-pivot',
  [ApiRouteTypes.GetMerchantPayoutIncrementTaxes]: '/merchant-payout-increment-taxes',
  [ApiRouteTypes.PostMerchantPayoutIncrementTaxes]: '/merchant-payout-increment-taxes',
  [ApiRouteTypes.PutMerchantPayoutIncrementTaxes]: (coreId: ID) =>
    route(['/merchant-payout-increment-taxes', coreId]),
  [ApiRouteTypes.PostMerchantPayoutIncrementTaxesBulk]: '/import-merchant-payout-increment-taxes',
  [ApiRouteTypes.DeleteMerchantPayoutIncrementTaxes]: (coreId?: ID) =>
    route(['/merchant-payout-increment-taxes', coreId]),
  [ApiRouteTypes.GetMerchantPayoutIncrements]: '/merchant-payout-increments',
  [ApiRouteTypes.PutMerchantPayoutIncrements]: (coreId: ID) =>
    route(['/merchant-payout-increments', coreId]),
  [ApiRouteTypes.GetMerchantPayoutIncrementExchangeRates]: '/merchant-payout-increment-exchange-rates',
  [ApiRouteTypes.PostMerchantPayoutIncrementExchangeRates]: '/merchant-payout-increment-exchange-rates',
  [ApiRouteTypes.DeleteMerchantPayoutIncrementExchangeRates]: (coreId?: ID) =>
    route(['/merchant-payout-increment-exchange-rates', coreId]),
  [ApiRouteTypes.PostMerchantPayoutIncrementExchangeRatesBulk]: '/import-merchant-payout-increment-exchange-rates',
  [ApiRouteTypes.GetMerchantPayoutFilesTransactionsCSV]: (coreId?: ID) =>
    route(['/merchant-payout-file-csv-transactions', coreId]),
  [ApiRouteTypes.GetMerchantPayoutSummaryFilesTransactionsCSV]: (coreId?: ID) =>
    route(['/merchant-payout-summary-file-csv-transactions', coreId]),
  [ApiRouteTypes.GetMerchantPayoutFilesStillPendingSettlementCSV]: (coreId?: ID) =>
    route(['/merchant-payout-file-csv-pending-settlement-transactions', coreId]),
  [ApiRouteTypes.GenerateMerchantPayoutFilesSettlements]: (coreId?: ID) =>
    route(['/merchant-payout-files', coreId]),
  [ApiRouteTypes.DeleteMerchantPayoutFiles]: (coreId?: ID) =>
    route(['/merchant-payout-files', coreId]),
  [ApiRouteTypes.PaidMerchantPayoutFiles]: (coreId?: ID) =>
    route(['/merchant-payout-files', coreId]),
  [ApiRouteTypes.PreviewMerchantPayoutFilesSettlementXLS]: (coreId?: ID, MOR?: string) =>
    route(['/settlement-sheet-merchant-payout-files', `${coreId}?MOR=${MOR}`]),
  [ApiRouteTypes.DeleteMerchantPayoutSummaryFiles]: (coreId: ID) =>
    route(['/merchant-payout-summary-files', coreId]),
  [ApiRouteTypes.GetMerchantsMethodsPivot]: '/merchants-methods-pivot',
  [ApiRouteTypes.GetMerchantsMethodsPivotHistory]: '/merchants-methods-pivot-history',
  [ApiRouteTypes.PostMerchantsMethodsPivot]: route(['merchants-methods-pivot']),
  [ApiRouteTypes.PutMerchantsMethodsPivot]: (coreId: ID) =>
    route(['merchants-methods-pivot', coreId]),
  [ApiRouteTypes.GetSetupsKYCSwitch]: '/setups/KYC_ON_OFF_SWITCH',
  [ApiRouteTypes.PutSetupsKYCSwitch]: '/setups/KYC_ON_OFF_SWITCH',
  [ApiRouteTypes.GetTicketingSystemSwitch]: '/setups/TICKETING_ON_OFF_SWITCH',
  [ApiRouteTypes.PutTicketingSystemSwitch]: '/setups/TICKETING_ON_OFF_SWITCH',
  [ApiRouteTypes.GetTicketingSystemZendeskSwitch]: '/setups/ZENDESK_TICKETING_ON_OFF_SWITCH',
  [ApiRouteTypes.PutTicketingSystemZendeskSwitch]: '/setups/ZENDESK_TICKETING_ON_OFF_SWITCH',
  [ApiRouteTypes.GetKycs]: '/kycs',
  [ApiRouteTypes.PostKycs]: '/kycs',
  [ApiRouteTypes.DeleteKYCs]: (pki: string) => route(['/kycs', pki]),
  [ApiRouteTypes.GetUsersPreferences]: (userId: ID) => route(['/users-preferences', userId]),
  [ApiRouteTypes.PutUsersPreferences]: (userId: ID) => route(['/users-preferences', userId]),
  [ApiRouteTypes.GetManualBulkKYCsImportHistory]: '/manual-bulk-kycs-import-history',
  [ApiRouteTypes.PostManualBulkKYCsImportHistory]: '/manual-bulk-kycs-import-history',
  [ApiRouteTypes.GetConsumersManualBulkKYCsImportHistoryPivot]: '/consumers-manual-bulk-kycs-import-history-pivot',
  [ApiRouteTypes.GetMerchantDomains]: '/merchant-domains',
  [ApiRouteTypes.PostMerchantDomains]: (coreId?: ID) => route(['merchant-domains', coreId]),
  [ApiRouteTypes.DeleteMerchantDomains]: (coreId: ID) => route(['merchant-domains', coreId]),
  [ApiRouteTypes.GetMerchantBusinesses]: (coreId?: ID) => route(['merchant-businesses', coreId]),
  [ApiRouteTypes.PostMerchantBusinesses]: (coreId?: ID) => route(['merchant-businesses', coreId]),
  [ApiRouteTypes.DeleteMerchantBusinesses]: (coreId: ID) => route(['merchant-businesses', coreId]),
  [ApiRouteTypes.GetBitsoBanks]: '/bitso-banks'
};
