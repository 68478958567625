import { useTypedSelector } from "hooks";
import { withSubscription } from "providers/ContextPublisher";
import { getDirtyFields, getValues, isFormChanged, withDirty } from "util/forms";
import { BasicInfo } from "ui/forms/Setup/PaymentMethod";
import { selectCountriesOptions, selectCurrencies } from "features/general/selectors";
import usePaymentMethodActions from "features/paymentMethods/useActions";
import type { PaymentMethodProviderProps } from "./types";

export default function usePaymentMethodBasicInfo(initialValues: PaymentMethodProviderProps['paymentMethod']) {
    const currencies = useTypedSelector(selectCurrencies);
    const countries = useTypedSelector(selectCountriesOptions({ id: 'iso2' }, ({ iso2 }) => iso2));

    const { updateOrCreatePaymentMethod } = usePaymentMethodActions();

    const bootstrapData = {
        isBootstrapDataLoading: false,
        bootstrapData: {
            forCurrencies: currencies,
            forCountries: countries
        },
        onSaveOrCreate: withDirty(updateOrCreatePaymentMethod, {
            getInitialValues: () => BasicInfo.getInitialValues(initialValues),
            addExtras: () => ({
                coreId: initialValues.coreId
            })
        })
    };

    const from = BasicInfo.useForm({
        ...bootstrapData,
        initialValues,
        onSaveOrCreate: bootstrapData.onSaveOrCreate
    });

    const hasChanges = () => isFormChanged(from);

    const getChangedValues = () => getValues(
        getDirtyFields({
            ...from,
            initialValues: BasicInfo.getInitialValues(initialValues)
        })
    );

    return withSubscription({
        ...bootstrapData,
        ...from,
        hasChanges,
        getChangedValues,
    }, `PaymentMethodDetailTab.BasicInfo${initialValues.coreId}`);
};
