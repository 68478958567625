import { memo, type SyntheticEvent } from "react";
import Box from "@mui/material/Box";
import TextField from '@mui/material/TextField';
import Button from "@mui/material/Button";
import { getFormInputProps } from "util/forms";
import Autocomplete from "ui/atoms/Autocomplete";
import { FormField, type SetupPaymentMethodBasicInfoProps } from "./types";
import { specInFieldValuesRequired } from "./useValidationSchema";

const SpecInParams = ({
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    validateField,
    values,
    isValid,
    isSubmitting,
    isBootstrapDataLoading,
    bootstrapData,
    ...restForm
}: SetupPaymentMethodBasicInfoProps) => {
    const commonSelectProps = {
        fullWidth: true,
        disableClearable: true,
        size: 'small' as const,
        layout: null,
        loading: isBootstrapDataLoading,
        disabled: isBootstrapDataLoading
    };

    const onSelectChange = (fieldName: FormField) => (_: SyntheticEvent, value: string) => {
        setFieldValue(fieldName, value);
        setFieldTouched(fieldName, true, true);
    };

    const onMethodSpecInFieldTypeChange = (event: SyntheticEvent, value: string) => {
        onSelectChange(FormField.Type)(event, value);
        setTimeout(() => setFieldTouched(FormField.Values, true, true));
    };

    return (
        <Box
            sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(5, 1fr) auto',
                gap: 1,
                alignItems: 'flex-start'
            }}
            component='form'
            onSubmit={handleSubmit}
            autoComplete='off'
        >
            <Autocomplete
                {...commonSelectProps}
                options={bootstrapData[FormField.SpecIn]}
                value={values[FormField.SpecIn]}
                onChange={onSelectChange(FormField.SpecIn)}
                TextFieldProps={{
                    label: 'Spec-In Field',
                    required: true
                }}
            />
            <Autocomplete
                {...commonSelectProps}
                options={bootstrapData[FormField.Option]}
                value={values[FormField.Option]}
                onChange={onSelectChange(FormField.Option)}
                TextFieldProps={{
                    label: 'Option',
                    required: true
                }}
            />
            <TextField
                {...getFormInputProps(FormField.Values, restForm)}
                size='small'
                name={FormField.Values}
                value={values[FormField.Values]}
                label='Values'
                onChange={handleChange}
                onBlur={handleBlur}
                required={specInFieldValuesRequired(values[FormField.Type])}
                fullWidth
            />
            <Autocomplete
                {...commonSelectProps}
                options={bootstrapData[FormField.Type]}
                value={values[FormField.Type]}
                onChange={onMethodSpecInFieldTypeChange}
                TextFieldProps={{
                    label: 'Type',
                    required: true
                }}
            />
            <TextField
                {...getFormInputProps(FormField.Description, restForm)}
                size='small'
                name={FormField.Description}
                value={values[FormField.Description]}
                label='Description'
                onChange={handleChange}
                onBlur={handleBlur}
                required
                fullWidth
            />
            <Button
                type='submit'
                variant='outlined'
                color='primary'
                disabled={!isValid || isSubmitting}
            >
                Add
            </Button>
        </Box>
    );
};

export default memo(SpecInParams);
