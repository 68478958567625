import type { PaymentMethod } from "features/paymentMethods/types";
import type { useForm } from "hooks";
import type { BootstrapableForm } from "types";

export const enum FormField {
    ForCurrencies = 'forCurrencies',
    Name = 'name',
    Tag = 'tag',
    ForCountries = 'forCountries',
    DayToHoldOfflineFunds = 'dayToHoldOfflineFunds',
    Provider = 'PSP',
    RequireSpecIn = 'requireSpecIn',
    SendPaymentReceiptMail = 'sendPaymentReceiptMail',
    SendPaymentRequestMail = 'sendPaymentRequestMail',
    IsAssignable = 'isAssignable'
};

export type SetupPaymentMethodBasicInfo = {
    readonly [FormField.ForCurrencies]: PaymentMethod['forCurrencies'] | Array<PaymentMethod['forCurrencies']>;
    readonly [FormField.ForCountries]: PaymentMethod['forCountries'] | Array<PaymentMethod['forCountries']>;
} & Pick<
    PaymentMethod,
    | 'name'
    | 'tag'
    | 'dayToHoldOfflineFunds'
    | 'PSP'
    | 'requireSpecIn'
    | 'sendPaymentReceiptMail'
    | 'sendPaymentRequestMail'
    | 'isAssignable'
>;

export type SetupPaymentMethodBasicInfoForm =
    & BootstrapableForm<SetupPaymentMethodBasicInfo, {
        readonly [FormField.ForCurrencies]: string[];
        readonly [FormField.ForCountries]: string[];
    }>;

export type SetupPaymentMethodBasicInfoProps =
    & SetupPaymentMethodBasicInfoForm
    & ReturnType<typeof useForm<SetupPaymentMethodBasicInfo>>;
