import { useRepository } from "./repository";

export default function usePaymentMethods() {
    const { getRepository } = useRepository();

    const fetchPaymentMethods = () =>
        getRepository()
            .fetchPaymentMethods();

    return {
        fetchPaymentMethods,
        /** Add domain methods here */
    };
};
