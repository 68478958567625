import { memo, PropsWithChildren } from "react";
import { CreateTaxableEntityProvider, type CreateTaxableEntityProviderProps } from "./CreateTaxableEntity";
import { DownloadCSVTemplateDialog, DownloadCSVTemplateDialogProps } from "./DownloadCSVTemplate";
import ImportCSVProvider from "./ImportCSV/ImportCSVProvider";
import type { ImportCSVProviderProps } from "./ImportCSV";

export type FrontPanelWidgetProviderProps =
    & CreateTaxableEntityProviderProps
    & DownloadCSVTemplateDialogProps
    & ImportCSVProviderProps;

export const FrontPanelWidgetProvider = ({
    children,
    downloadTemplateHandler,
    importBulkRequestThunk,
    ...restCreateTaxableEntityProviderProps
}: PropsWithChildren<FrontPanelWidgetProviderProps>) => (
    <CreateTaxableEntityProvider
        {...restCreateTaxableEntityProviderProps}
    >
        <ImportCSVProvider
            importBulkRequestThunk={importBulkRequestThunk}
        >
            <DownloadCSVTemplateDialog
                downloadTemplateHandler={downloadTemplateHandler}
            >
                {children}
            </DownloadCSVTemplateDialog>
        </ImportCSVProvider>
    </CreateTaxableEntityProvider>
);

export default memo(FrontPanelWidgetProvider);
