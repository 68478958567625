import { OpenBankingResultStatus } from "consts/transactions";
import { ApiState } from "infrastructure/api";
import { RootState } from "infrastructure/store";
import type { Indexed, Option } from "types";
import { capitalizeFirstLetterFormatter, removeUnderscoreFormatter } from "util/formaters";
import { compareIds } from "util/support";
import { convertToOptions } from "util/transformers";
import type { BitsoBank, Country } from "./types";

export const selectMenuOpenState = (state: RootState) =>
  state.general.isSideMenuOpen;

export const selectNotification = (state: RootState) =>
  state.general.notifications;

export const selectConstants = (state: RootState) =>
  state.general.constants;

export const selectIsBootstrapDataLoading = (state: RootState) =>
  state.general.constantsLoadingState === ApiState.Pending;

export const selectIsBootstrapDataSucceeded = (state: RootState) =>
  state.general.constantsLoadingState === ApiState.Succeeded;

export const selectCountries = (state: RootState) =>
  state.general.countries;

export const selectCurrencies = (state: RootState) =>
  state.general.constants.Currencies;

export const selectIsBitsoBanksLoading = (state: RootState) =>
  state.general.bitsoBanksLoadingState === ApiState.Pending;

export const selectBitsoBanks = (state: RootState) =>
  state.general.bitsoBanks;

export const selectBitsoBanksOptions = (state: RootState): Array<Option> =>
  selectBitsoBanks(state)?.data.map(({
    bitsoBankCode,
    bitsoBankName
  }: BitsoBank) => ({
    id: bitsoBankCode,
    name: bitsoBankName
  })) ?? [];

export const selectTransactionCurrencies = (state: RootState) =>
  selectConstants(state).TransactionCurrencies;

export const selectCryptoCurrencies = (state: RootState) =>
  state.general.constants.CryptoCurrencies;

export const selectCryptoChains = (state: RootState) =>
  state.general.constants.CryptoChains;

export const selectMerchantMethodPivotActiveDomain = (state: RootState) =>
  state.general.constants.MerchantMethodPivotActiveDomainOption;

export const selectCountry = (keyValue: Partial<Indexed<Country>>, defaultValue = null) =>
  (state: RootState) => {
    const [key, value] = Object.entries(keyValue).at(0)!;

    const countryProperty = key as keyof Country;

    return state.general.countries.find((country: Country) =>
      compareIds(country[countryProperty], value)) ?? defaultValue;
  };

export const selectCountriesOptions = <
  TId extends keyof Country,
  TName extends keyof Country
>(option: Readonly<{
  id: TId;
  name?: TName;
}>, transformer?: (country: Indexed<Country>) => string | Array<Option>) =>
  (state: RootState) => {
    const callbackfn = transformer ?? ((country: Indexed<Country>) => ({
      id: country[option.id],
      name: country[option.name ?? option.id]
    }));

    return selectCountries(state).map(callbackfn);
  };

export const selectDomains = (state: RootState) =>
  state.general.domains;

export const selectIsCountriesLoading = (state: RootState) =>
  state.general.countriesLoadingState === ApiState.Pending;

export const selectSetupValues = (state: RootState) =>
  Object.keys(state.general.domains?.setupValue ?? {});

export const selectDomainOptions = (state: RootState) =>
  convertToOptions(selectSetupValues(state));

export const selectBootstrapData = (state: RootState) => ({
  ...selectConstants(state),
  Domains: selectSetupValues(state)
});

export const selectMerchantPayoutIncrementsVia = (state: RootState) =>
  convertToOptions(
    state.general.constants.MerchantPayoutIncrementsVia,
    capitalizeFirstLetterFormatter
  )

export const selectViaAutoSuggestModel = (state: RootState) =>
  state.general.constants.Via.map(({ name }: Option) => ({
    id: name
      .charAt(0)
      .toLocaleLowerCase(),
    name
  }));

export const selectCalulationStatus = (state: RootState) =>
  state.general.constants.MerchantPayoutCalculationEnums.map((calculationStatus: string) => ({
    id: calculationStatus,
    name: removeUnderscoreFormatter(calculationStatus)
  }));

export const selectFileTaxName = (state: RootState) =>
  state.general.constants.MerchantPayoutFileTaxName;

export const selectParentVisibility = (state: RootState) =>
  state.general.extras.parentVisibility;

export const selectObResultStatusText = (obResultStatus: OpenBankingResultStatus) =>
  (state: RootState) =>
    state.general.constants.ObResultStatuses.find(({ id }: Option) =>
      compareIds(id, obResultStatus))?.name ?? '';

export const selectCountryByProperty = ([propertyName, value]: [keyof Country, string]) =>
  (state: RootState) =>
    state.general.countries.find((country: Country) =>
      compareIds(country[propertyName], value));

export const selectUserPreferences = (state: RootState) =>
  state.general.userPreferences;

export const selectUserPreferencesLoadingState = (state: RootState) =>
  state.general.userPreferencesLoadingState;

export const selectKycTypesMap = (state: RootState) =>
  selectConstants(state).KycTypesMap;

export const selectMerchantGroupNames = (state: RootState) =>
  selectConstants(state).MerchantGroupNames;

export const selectPayoutCycles = (state: RootState) =>
  selectConstants(state).PayoutCycles;

export const selectAccountWithdrawalOptions = (state: RootState) =>
  selectConstants(state).AccountWithdrawalOptions;
