import { memo, useState } from 'react';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import usePaymentMethodActions from "features/paymentMethods/useActions";
import { ConfirmableAction } from 'ui/molecules/Action';
import { usePaymentMethodContext } from './PaymentMethodProvider';

const PaymentMethodAction = () => {
    const [isProcessing, setProcessingState] = useState(false);

    const { updateOrCreatePaymentMethod } = usePaymentMethodActions();

    const { hasActiveChanges, getChangedValues } = usePaymentMethodContext();

    const onConfirm = async () => {
        try {
            setProcessingState(true);

            const [request] = updateOrCreatePaymentMethod(getChangedValues());

            await request
        } finally {
            setProcessingState(false);
        }
    };

    const dialogContent = {
        dialogContent: [
            <>
                <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                        fontSize: '18px'
                    }}
                >
                    Changes to payment method have been made. Are you sure you want to save these adjustments?
                </Typography>
            </>
        ],
        actionContent: 'Save Changes'
    };

    return (
        <ConfirmableAction
            ActionProps={{
                children: (
                    <LoadingButton
                        loading={isProcessing}
                        variant='contained'
                        color='primary'
                        disabled={!hasActiveChanges()}
                    >
                        Save
                    </LoadingButton>
                )
            }}
            DialogProps={{
                content: [
                    dialogContent,
                    dialogContent
                ],
                onConfirm,
                onCancel: () => {},
                checkPredicate: () => false,
                cancelActionContentSlot: 'Cancel'
            }}
        />
    );
};

export default memo(PaymentMethodAction);