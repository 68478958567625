import type { PaymentMethod } from "features/paymentMethods/types";
import type usePaymentMethodDetail from "./usePaymentMethod";
import type { AccordionProps } from "ui/atoms/Accordion";

export const enum PaymentMethodDetailTab {
    BasicInfo = 'BasicInfo',
    SpecificInputParameters = 'SpecificInputParameters'
};


export type PaymentMethodContext = ReturnType<typeof usePaymentMethodDetail>;

export type PaymentMethodProviderProps = Pick<
    AccordionProps,
    | 'expanded'
    | 'onChange'
> & {
    readonly paymentMethod: PaymentMethod;
};
