import type { PayloadAction } from "@reduxjs/toolkit";
import type { WritableDraft } from "immer/dist/internal"
import { ApiState } from "infrastructure/api";
import { compareIds } from "util/support";
import type { ThunkReturnType } from "types";
import type {
    PaymentMethod,
    PaymentMethodResponse,
    PaymentMethodsResponse,
    PaymentMethodsState
} from "./types";

export const paymentMethodsRequestLoading = (state: WritableDraft<PaymentMethodsState>) => {
    state.paymentMethodsLoadingState = ApiState.Pending;
};

export const paymentMethodsRequestFulfilled = (
    state: WritableDraft<PaymentMethodsState>,
    { payload }: PayloadAction<ThunkReturnType<PaymentMethodsResponse>>) => {
    state.paymentMethodsLoadingState = ApiState.Succeeded;
    state.paymentMethodsSlice = payload!.data;
};

export const paymentMethodsRequestRejected = (state: WritableDraft<PaymentMethodsState>) => {
    state.paymentMethodsLoadingState = ApiState.Failed;
    state.paymentMethodsSlice = null;
};

export const updateOrCreatePaymentMethodRequestFulfilled = (
    state: WritableDraft<PaymentMethodsState>,
    { payload }: PayloadAction<ThunkReturnType<PaymentMethodResponse>>
) => {
    state.paymentMethodsLoadingState = ApiState.Succeeded;

    let isUpdated = false;
    state.paymentMethodsSlice!.data =
        state.paymentMethodsSlice!.data.map((paymentMethod: PaymentMethod) => {
            if (compareIds(paymentMethod.coreId, payload!.data.coreId)) {
                isUpdated = true;

                return ({
                    ...paymentMethod,
                    ...payload!.data
                });
            }

            return paymentMethod;
        });

    if (!isUpdated) {
        state.paymentMethodsSlice!.data.unshift(payload!.data);
        state.paymentMethodsSlice!.to += 1;
        state.paymentMethodsSlice!.total += 1;
    }
};

export const updateOrCreatePaymentMethodRequestRejected = (state: WritableDraft<PaymentMethodsState>) => {
    state.paymentMethodsLoadingState = ApiState.Failed;
};
