import { memo } from "react";
import Typography from "@mui/material/Typography";
import type { BoxProps } from "@mui/material/Box";
import { CurrencySymbol } from "consts/general";
import { TextFieldLoadableProps } from "ui/atoms/TextField";
import { EditableFee } from "ui/forms/Merchant/FeesAndCommisions";
import useMerchantFeesAndCommisions from "../useMerchantFeesAndCommisions";
import GroupLayout from "../GroupLayout";

const GeneralFees = (props: BoxProps) => {
    const {
        repository,
        updateGeneralFee
    } = useMerchantFeesAndCommisions();

    const generalFees = repository.generalFees;

    const sx = {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gap: 2,
        ...props.sx
    };

    const WithTextFieldProps = ({ label, ...textFieldProps }: TextFieldLoadableProps): TextFieldLoadableProps => ({
        ...textFieldProps,
        type: 'number',
        loading: repository.isMerchantSettingsLoading,
        InputProps: {
            endAdornment: (
                <Typography
                    sx={{
                        fontWeight: 600
                    }}
                >
                    {label}
                </Typography>
            )
        }
    })

    return (
        <GroupLayout>
            <EditableFee
                sx={sx}
                onSave={updateGeneralFee}
                TextFieldProps={WithTextFieldProps({
                    name: 'chargebackUnitFee',
                    value: generalFees.chargebackUnitFee,
                    label: CurrencySymbol.EUR
                })}
            >
                Chargeback Fee
            </EditableFee>
            <EditableFee
                sx={sx}
                onSave={updateGeneralFee}
                TextFieldProps={WithTextFieldProps({
                    name: 'chargebacksNoThresholdPercent',
                    value: generalFees.chargebacksNoThresholdPercent,
                    label: '%'
                })}
            >
                Chargeback No. %
            </EditableFee>
            <EditableFee
                sx={sx}
                onSave={updateGeneralFee}
                TextFieldProps={WithTextFieldProps({
                    name: 'payoutTransFee',
                    value: generalFees.payoutTransFee,
                    label: CurrencySymbol.EUR
                })}
            >
                Transfer fee
            </EditableFee>
            <EditableFee
                sx={sx}
                onSave={updateGeneralFee}
                TextFieldProps={WithTextFieldProps({
                    name: 'payoutTransAmountThreshold',
                    value: generalFees.payoutTransAmountThreshold,
                    label: CurrencySymbol.EUR
                })}
            >
                Min Settlement Threshold
            </EditableFee>
            <EditableFee
                sx={sx}
                onSave={updateGeneralFee}
                TextFieldProps={WithTextFieldProps({
                    name: 'rollingReserveHoldDays',
                    value: generalFees.rollingReserveHoldDays,
                    label: 'days'
                })}
            >
                Rolling Reserve Hold Days
            </EditableFee>
        </GroupLayout>
    );
};

export default memo(GeneralFees);
