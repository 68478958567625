import { memo } from "react";
import Accordion from "ui/atoms/Accordion";
import PaymentMethodSummary from "./PaymentMethodSummary";
import PaymentMethodDetail, {
    PaymentMethodDetailProvider,
    type PaymentMethodProviderProps
} from "./PaymentMethodDetail";
import PaymentMethodAction from "./PaymentMethodAction";

const PaymentMethod = (props: PaymentMethodProviderProps) => (
    <PaymentMethodDetailProvider
        {...props}
    >
        <Accordion
            {...props}
            AccordionSummaryProps={{
                children: (
                    <PaymentMethodSummary />
                ),
                'aria-controls': `${props.paymentMethod.name} content`,
                id: `${props.paymentMethod.name} summary`
            }}
            AccordionActionsProps={{
                children: (
                    <PaymentMethodAction />
                )
            }}
        >
            <PaymentMethodDetail />
        </Accordion>
    </PaymentMethodDetailProvider>
);

export default memo(PaymentMethod);
