import { memo, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { SortOrder } from "consts/request-query";
import { downloadIncremetTaxesCSVTemplate } from "features/merchantPayouts/api";
import { postMerchantPayoutIncrementTaxesBulkThunk } from "features/merchantPayouts/thunks";
import { IncrementTaxesModel } from "ui/forms/IncrementTaxes";
import { TabLayout } from "ui/widgets/StatefulTabs";
import { TaxableFrontPanelWidgetProvider } from "ui/organizms/MerchantPayouts";
import GeneralFees from "./GeneralFees";
import GroupedFees from "./GroupedFees";
import MethodFees from "./MethodFees";
import MerchantFeesAndCommisionsActions from "./MerchantFeesAndCommisionsActions";
import { AddMerchantFeeDialog } from "./AddMerchantFee";
import MerchantProvider, { useMerchantContext } from "../MerchantProvider";
import useMerchantFeesAndCommisions from "./useMerchantFeesAndCommisions";

const MerchantFeesAndCommisions = () => {
    const merchant = useMerchantContext();

    const { repository, getGeneralFees } = useMerchantFeesAndCommisions();
    const repositoryRef = useRef(repository);
    repositoryRef.current = repository;

    useEffect(() => {
        repositoryRef.current
            .fetchMerchantFeesAndCommisions();
    }, []);

    const sx = {
        maxWidth: 600
    };

    const cardSx = {
        maxWidth: sx.maxWidth + 10
    };

    return (
        <TaxableFrontPanelWidgetProvider
            withInitialValues={(initialValuesModel = IncrementTaxesModel
                .init()
                .initSingleOptionValues({})
            ) => initialValuesModel.initSingleOptionValues({
                merchantId: merchant.coreId
            })}
            importBulkRequestThunk={postMerchantPayoutIncrementTaxesBulkThunk}
            downloadTemplateHandler={downloadIncremetTaxesCSVTemplate}
            createTaxableActionLabel='Create Merchant Fee'
        >
            <TabLayout
                title={(
                    <Typography
                        sx={{
                            fontWeight: 600,
                            whiteSpace: 'nowrap'
                        }}
                    >
                        General Fees
                    </Typography>
                )}
                auxSlot={(
                    <MerchantFeesAndCommisionsActions />
                )}
            >
                <Box
                    sx={{
                        display: 'grid',
                        gap: 4
                    }}
                >
                    <GeneralFees
                        sx={sx}
                    />
                    <GroupedFees
                        sx={cardSx}
                        incrementTaxes={getGeneralFees()}
                        sortOrder={SortOrder.Asc}
                    />
                    <MethodFees
                        sx={cardSx}
                    />
                </Box>
            </TabLayout>
            <AddMerchantFeeDialog />
        </TaxableFrontPanelWidgetProvider>
    );
};

export default memo(() => (
    <MerchantProvider>
        <MerchantFeesAndCommisions />
    </MerchantProvider>
));
