import type { IDomainRepository } from "hooks/useDomainState";
import type { DomainAction } from "types";
import { ApiState } from "infrastructure/api";

export const enum PaymentMethodsActionType {
    FetchPaymentMethods = 'PaymentMethodsActionType:FetchPaymentMethods',
    Reset = 'PaymentMethodsActionType:Reset'
};

export type PaymentMethodsRepositoryState = {
    apiState: ApiState;
    /**
     * Payment method domain properties
     */
};

export type FetchPaymentMethodsActionPayload = object;

export type PaymentMethodRepositoryAction =
    | DomainAction<
        PaymentMethodsActionType.FetchPaymentMethods,
        FetchPaymentMethodsActionPayload
    >
    | DomainAction<
        PaymentMethodsActionType.Reset,
        undefined
    >;

export interface PaymentMethodsRepository extends IDomainRepository {
    readonly state: PaymentMethodsRepositoryState;

    readonly isLoading: boolean;

    readonly isEmpty: boolean;

    readonly isError: boolean;

    fetchPaymentMethods(): Promise<void>;
}
