import { useEffect, useState } from "react";
import type { FormikHelpers } from "formik";
import { useTypedSelector } from "hooks";
import { withSubscription } from "providers/ContextPublisher";
import { isEqual } from "util/support";
import { parse } from "util/parsers";
import { SpecInParams } from "ui/forms/Setup/PaymentMethod";
import { selectBootstrapData } from "features/general/selectors";
import type { PaymentMethodProviderProps } from "./types";

export default function usePaymentMethodSpecInParams(initialValues: PaymentMethodProviderProps['paymentMethod']) {
    const [specInParameters, setSpecInParameters] = useState<Array<SpecInParams.SetupPaymentMethodSpecInParams>>([]);
    const {
        TransactionSpecins,
        PaymentMethodSpecinFieldTypes,
        PaymentMethodSpecinOptionTypes
    } = useTypedSelector(selectBootstrapData);

    const bootstrapData = {
        isBootstrapDataLoading: false,
        bootstrapData: {
            [SpecInParams.FormField.SpecIn]: TransactionSpecins,
            [SpecInParams.FormField.Option]: PaymentMethodSpecinOptionTypes,
            [SpecInParams.FormField.Type]: PaymentMethodSpecinFieldTypes
        },
        onSaveOrCreate: (
            values: Partial<SpecInParams.SetupPaymentMethodSpecInParams>,
            formikHelpers?: FormikHelpers<Partial<SpecInParams.SetupPaymentMethodSpecInParams>>
        ) => {
            setSpecInParameters(state => [
                ...state,
                values as SpecInParams.SetupPaymentMethodSpecInParams
            ]);

            formikHelpers!.resetForm();
        }
    };

    const deleteSpecInParamsByIndex = (index: number) =>
        setSpecInParameters(state => state.filter((_, i) => !Object.is(i, index)));

    useEffect(() => {
        setSpecInParameters(
            parse(initialValues.specInParameters, [])
        );
    }, [initialValues.specInParameters]);

    const hasChanges = () => !isEqual(
        initialValues.specInParameters,
        JSON.stringify(specInParameters)
    );

    const getChangedValues = (): Partial<PaymentMethodProviderProps['paymentMethod']> => (
        hasChanges()
            ? {
                specInParameters: JSON.stringify(specInParameters)
            }
            : {}
    );

    return {
        ...withSubscription({
            ...bootstrapData,
            ...SpecInParams.useForm({
                ...bootstrapData,
                onSaveOrCreate: bootstrapData.onSaveOrCreate
            })
        }, `PaymentMethodDetailTab.SpecificInputParameters${initialValues.coreId}`),
        isValid: true,
        specInParameters,
        hasChanges,
        getChangedValues,
        deleteSpecInParamsByIndex
    };
};
