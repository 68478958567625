import { ApiState } from "infrastructure/api";
import type { CoreBankingEntity, DomainAction } from "types";
import type { IDomainRepository } from "hooks";
import type { MerchantSettings } from "features/merchants/types";
import type { IncrementTaxesResponse as MerchantPayoutIncrementTaxesResponse, IncrementTaxes } from "features/merchantPayouts/types";
import type { MerchantSettingsRepository } from "../../repository";

export const enum MerchantFeesAndCommisionsActionType {
    FetchMerchantPayoutIncrementTaxes = 'MerchantFeesAndCommisionsActionType:FetchMerchantPayoutIncrementTaxes',
    AddMerchantPayoutIncrementTaxes = 'MerchantFeesAndCommisionsActionType:AddMerchantPayoutIncrementTaxes',
    UpdateMerchantPayoutIncrementTaxes = 'MerchantFeesAndCommisionsActionType:UpdateMerchantPayoutIncrementTaxes',
    DeleteMerchantPayoutIncrementTaxes = 'MerchantFeesAndCommisionsActionType:DeleteMerchantPayoutIncrementTaxes',
    Reset = 'MerchantFeesAndCommisionsActionType:Reset'
};

export type GeneralFees = Partial<
    Pick<
        MerchantSettings,
        | 'chargebackUnitFee'
        | 'chargebacksNoThresholdPercent'
        | 'payoutTransFee'
        | 'payoutTransAmountThreshold'
        | 'rollingReserveHoldDays'
    >
>;

export type MerchantPayoutIncrementTaxes = {
    readonly apiState: ApiState;
    readonly data: MerchantPayoutIncrementTaxesResponse['data'] | null;
};

export type MerchantFeesAndCommisionsRepositoryState = MerchantPayoutIncrementTaxes;

export type MerchantFeesAndCommisionsRepositoryAction =
    | DomainAction<
        MerchantFeesAndCommisionsActionType.FetchMerchantPayoutIncrementTaxes,
        MerchantPayoutIncrementTaxes
    >
    | DomainAction<
        MerchantFeesAndCommisionsActionType.AddMerchantPayoutIncrementTaxes,
        IncrementTaxes
    >
    | DomainAction<
        MerchantFeesAndCommisionsActionType.UpdateMerchantPayoutIncrementTaxes,
        IncrementTaxes
    >
    | DomainAction<
        MerchantFeesAndCommisionsActionType.DeleteMerchantPayoutIncrementTaxes,
        CoreBankingEntity
    >
    | DomainAction<
        MerchantFeesAndCommisionsActionType.Reset,
        undefined
    >;

export interface MerchantFeesAndCommisionsRepository extends MerchantSettingsRepository, IDomainRepository {
    readonly state: MerchantFeesAndCommisionsRepositoryState;

    readonly generalFees: GeneralFees;

    readonly merchantPayoutIncrementTaxes: MerchantPayoutIncrementTaxesResponse['data']['data'];

    fetchMerchantFeesAndCommisions(): Promise<void>;

    addMerchantPayoutIncrementTaxes(merchantPayoutIncrementTaxes: IncrementTaxes): void;

    updateMerchantPayoutIncrementTaxes(merchantPayoutIncrementTaxes: IncrementTaxes): void;

    deleteMerchantPayoutIncrementTaxes<T extends CoreBankingEntity>(merchantPayoutIncrementTaxes: T): void;
}
