import { memo, type SyntheticEvent } from "react";
import { selectCountries, selectIsCountriesLoading } from "features/general/selectors";
import type { Country } from "features/general/types";
import { useTypedSelector } from "hooks";
import Autocomplete, { type AutocompleteProps } from "ui/atoms/Autocomplete";
import CountrySelectorOption from "./CountrySelectorOption";
import type { FilterOptionsState } from "@mui/material";

export type CountrySelectorProps = Omit<
    AutocompleteProps,
    | 'renderInput'
    | 'options'
> & {
    readonly value: Country | Array<Country> | null;
    readonly onChange: (event: SyntheticEvent<Element, Event>, value: Country | Array<Country>) => void;
};

const CountrySelector = ({ value, onChange, ...restAutocompleteProps }: CountrySelectorProps) => {
    const isOptionsLoading = useTypedSelector(selectIsCountriesLoading);
    const options = useTypedSelector(selectCountries);

    const isOptionEqualToValue = (option: Country, value: Country) =>
        Object.is(option.coreId, value.coreId);

    const getOptionLabel = ({ shortName }: Country) => shortName;

    const filterOptions: AutocompleteProps['filterOptions'] = (
        options: Array<Country>,
        { inputValue }: FilterOptionsState<any>
    ) =>
        options.filter(({ shortName, iso2, iso3 }) => {
            const searchValue = inputValue?.toLowerCase();

            return (
                shortName.toLowerCase().includes(searchValue) ||
                iso2.toLowerCase().includes(searchValue) ||
                iso3.toLowerCase().includes(searchValue)
            );
        });

    return (
        <Autocomplete
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={isOptionEqualToValue}
            filterOptions={filterOptions}
            loading={isOptionsLoading}
            renderOption={(props, option, state) => (
                <CountrySelectorOption
                    key={option.coreId}
                    props={props}
                    option={option}
                    state={state}
                />
            )}
            {...restAutocompleteProps}
            TextFieldProps={{
                placeholder: 'Select country',
                ...restAutocompleteProps?.TextFieldProps
            }}
            options={options}
            value={value}
            onChange={onChange}
        />
    );
};

export default memo(CountrySelector);
