import { memo, useEffect, useRef } from "react";
import type { GridRenderCellParams } from "@mui/x-data-grid-premium";
import type { CoreBankingEntity, ResourceResponse } from "types";
import { SwitchableAction } from "ui/molecules/Action";

type Props = GridRenderCellParams & {
    readonly isProcessing: boolean;
    readonly getChecked: (params: GridRenderCellParams) => boolean;
    readonly handleProcess: (
        isChecked: boolean,
        params: GridRenderCellParams
    ) => Promise<ResourceResponse<CoreBankingEntity>>;
    readonly update?: (data: CoreBankingEntity) => void;
    readonly dialogContentDescription?: {
        dialogContent: string[],
        actionContent: string 
    }
};

const Status = ({
    isProcessing,
    getChecked,
    handleProcess,
    update,
    dialogContentDescription,
    ...props
}: Props) => {
    const timeoutIdRef = useRef<ReturnType<typeof setTimeout>>();

    const updateStatus = async (isChecked: boolean) => {
        clearTimeout(timeoutIdRef.current);

        const result = await handleProcess(isChecked, props);

        timeoutIdRef.current = setTimeout(() => update?.(result.data), 500);

        return result;
    };

    const getDisabled = () => isProcessing;

    const checkPredicate = () => false;

    const getTransitionStatus = () => {
        if (getChecked(props)) {
            return 'Inactive';
        }

        return 'Active';
    };

    const defaultDialogContent = {
        dialogContent: [
             `Are you sure you would like to change status to ${getTransitionStatus()}?`
        ],
        actionContent: 'Yes'
    };

    const dialogContent = dialogContentDescription || defaultDialogContent;
    useEffect(() => () => clearTimeout(timeoutIdRef.current), []);

    return (
        <SwitchableAction
            undoableDialog={{
                handleProcess: updateStatus,
                i18n: [<></>],
                getDefaultChecked: () => getChecked(props),
                getDisabled
            }}
            ConfirmatableDialogProps={{
                isProcessing,
                checkPredicate,
                content: [
                    dialogContent,
                    dialogContent
                ],
                cancelActionContentSlot: 'No'
            }}
        />
    );
};

export default memo(Status);
