import { useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, MenuItem } from '@mui/material';
// routes
// import { PATH_DASHBOARD, PATH_AUTH } from '../../../routes/paths';
// auth
// import { useAuthContext } from '../../../auth/useAuthContext';
// components
import { CustomAvatar } from 'lib/custom-avatar';
import { useSnackbar } from 'lib/snackbar';
import MenuPopover from 'lib/menu-popover';
import { IconButtonAnimate } from 'lib/animate';
import useActions from 'features/auth/useActions';
import useAuth from 'features/auth/useAuth';
import { useUnsavedChangesContext } from 'ui/widgets/UnsavedChanges';

// ----------------------------------------------------------------------

// const OPTIONS = [
//   {
//     label: 'Home',
//     linkTo: '/',
//   },
//   {
//     label: 'Profile',
//     linkTo: WebRoutes.Home,
//   },
//   {
//     label: 'Settings',
//     linkTo: WebRoutes.Home,
//   },
// ];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  // const navigate = useNavigate();
  const { checkUnsavedChanges } = useUnsavedChangesContext();
  const { logout } = useActions();
  // const { user, logout } = useAuthContext();
  const { getUserName, user } = useAuth();

  const { enqueueSnackbar } = useSnackbar();

  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleLogout = async () => {
    try {
      const [request] = logout();
      await request;
      // navigate(PATH_AUTH.login, { replace: true });
      handleClosePopover();
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const onLogout = () =>
    checkUnsavedChanges({
      onConfirm: handleLogout
    }).then(handleLogout);

  // const handleClickItem = (path: string) => {
  //   handleClosePopover();
  //   navigate(path);
  // };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpenPopover}
        sx={{
          p: 0,
          ...(openPopover && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <CustomAvatar
          alt={getUserName()}
          name={getUserName()}
        />
      </IconButtonAnimate>

      <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 200, p: 0 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {getUserName()}
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        {/* <Stack sx={{ p: 1 }}>
          {OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
              {option.label}
            </MenuItem>
          ))}
        </Stack> */}

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          sx={{
            m: 1
          }}
          onClick={onLogout}
        >
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}
