import { selectIsPaymentMethodsLoading, selectPaymentMethodsData } from "features/paymentMethods/selectors";
import useActions from "features/paymentMethods/useActions";
import { useTypedSelector } from "hooks";
import useSessionEffect from "hooks/useSessionEffect";

const paymentMethodsEffectSymbol = Symbol('paymentMethodsEffectSymbol');

export default function usePaymentMethods() {
    const { getAllPaymentMethods } = useActions();
    const isPaymentMethodsLoading = useTypedSelector(selectIsPaymentMethodsLoading);
    const paymentMethods = useTypedSelector(selectPaymentMethodsData)

    useSessionEffect(() => {
        getAllPaymentMethods();
    }, paymentMethodsEffectSymbol);

    return {
        paymentMethods, 
        isPaymentMethodsLoading
    };
};
