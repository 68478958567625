import { useDomainRepository } from "hooks/useDomainState";
import { PaymentMethodsActionType, type PaymentMethodsRepositoryState } from "./types";
import reducer from "./reducer";
import stateFactory from "./state";
import Repository from "./repository";
import BusinessLogicException from "exceptions/BusinessLogicException";

export default function useRepository() {
    const repository = useDomainRepository<
        PaymentMethodsRepositoryState,
        PaymentMethodsActionType
    >({
        reducer,
        initializerArg: stateFactory(),
        factory: (state, dispatch) => new Repository(
            state,
            dispatch,
            /** Here add necessary DI request factories */
        )
    });

    const getRepository = () => {
        if (repository instanceof Repository) {
            return repository;
        }

        throw new BusinessLogicException(`Happy Dmitry's retirement!`, {});
    };

    return {
        getRepository
    };
};
