import { memo, type PropsWithChildren } from "react";
import { capitalize } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import type { MerchantsMethodsPivot } from "features/pivots/types";
import { CustomAvatar } from "lib/custom-avatar";
import MerchantPaymentMethodPSP from "./MerchantPaymentMethodPSP";

type Props = {
    readonly name: MerchantsMethodsPivot['paymentMethodTag'];
    readonly merchantsMethods: Array<MerchantsMethodsPivot>;
};

const MerchantPaymentMethod = ({
    name,
    merchantsMethods,
    children
}: PropsWithChildren<Props>) => (
    <Box>
        <Box
            sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(3, min-content)',
                alignItems: 'center',
                gap: 2
            }}
        >
            <CustomAvatar
                name={name}
                alt={name}
            />
            <Typography
                variant='h5'
            >
                {capitalize(name)}
            </Typography>
            {children}
        </Box>
        <Box>
            {merchantsMethods.map(merchantMethod => (
                <MerchantPaymentMethodPSP
                    {...merchantMethod}
                    key={merchantMethod.coreId}
                />
            ))}
        </Box>
    </Box>
);

export default memo(MerchantPaymentMethod);
