import { memo, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Tooltip from "@mui/material/Tooltip";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { TriggerableDialog } from "ui/molecules/Dialog";
import type { SpecInParamsRowActionsProps } from "./types";

const DeleteSpecInParamsRow = ({ onDelete }: SpecInParamsRowActionsProps) => {
    const [isDeleting, setDeleting] = useState(false);

    return (
        <TriggerableDialog
            TriggerComponent={({ onClick }) => (
                <Tooltip
                    arrow
                    placement='bottom-end'
                    title='Delete Spec In Params Row'
                >
                    <IconButton
                        onClick={onClick}
                        color='error'
                    >
                        <HighlightOffIcon />
                    </IconButton>
                </Tooltip>
            )}
            DialogActionsComponent={({ onClose }) => (
                <>
                    <Button
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        variant='contained'
                        onClick={async () => {
                            try {
                                setDeleting(true);

                                await onDelete();
                                onClose();
                            } finally {
                                setDeleting(false);
                            }
                        }}
                        loading={isDeleting}
                    >
                        Delete spec-in param
                    </LoadingButton>
                </>
            )}
            ModalProps={{
                DialogProps: {
                    sx: {
                        '& .MuiDialog-container .MuiPaper-root': {
                            width: 'auto',
                            '& .MuiDialogContent-root': {
                                p: 0,
                                border: 'none'
                            }
                        }
                    }
                },
                titleSlot: 'Are you sure you want to delete this spec-in param?'
            }}
        >
            <Typography
                variant='body2'
                sx={{
                    p: 2,
                    textAlign: 'center'
                }}
            >
                This might affect the flow of the payment method.
            </Typography>
        </TriggerableDialog>
    );
};

export default memo(DeleteSpecInParamsRow);
